import http from '../clients/Axios'

export default class RefService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param = filters_param + '&' + el + '=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/ref/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  // async paginate(param) {
  //   param = param ? param : ''
  //   const filter = {}
  //   filter.page = param.page
  //   filter.rows = param.rows
  //   filter.sortField = param.sortField
  //   filter.sortOrder = param.sortOrder

  //   filter.name = param.name

  //   const res = await http.post('/ref/paginate', JSON.stringify(filter))
  //   return res
  // }

  async get() {
    const res = await http.get('/ref')
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/ref/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/ref', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/ref/${id}`, form)
    return res
  }

  async delete(item) {
    const res = await http.delete(`/ref/${item}`)
    return res
  }

  async getTags() {
    const res = await http.get(`/ref/tags`)
    return res
  }
}
