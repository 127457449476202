<template>
  <div>
    <!-- <form @submit.prevent="handleSubmit(!v$.$invalid)"> -->
    <div class="grid">
      <div class="col-12">
        <div class="formgrid grid">
          <div class="field col-12 md:col-5">
            <label>Nama</label>
            <InputText
              v-model="form.name"
              ref="name"
              :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
              class="w-full"
              input-class="w-full"
              maxlength="35"
              @keydown.enter="enterName"
            />
            <small
              v-if="
                (v$.form.name.$invalid && submitted) ||
                v$.form.name.$pending.$response
              "
              class="p-error"
              >{{ v$.form.name.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-5">
            <label>sku</label>
            <InputText
              ref="sku"
              v-model="form.sku"
              class="w-full"
              maxlength="35"
              input-class="w-full"
              :class="{ 'p-invalid': v$.form.sku.$invalid && submitted }"
            />
             <small
              v-if="
                (v$.form.sku.$invalid && submitted) ||
                v$.form.sku.$pending.$response
              "
              class="p-error"
              >{{ v$.form.sku.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2 md:mt-3">
            <br />
            <input
              ref="active"
              :checked="form.active"
              type="checkbox"
              id="active"
              @click="OnCheckClick"
            /><label for="active">Aktif</label>
          </div>
          <div class="field col-12 md:col-5">
            <label>Barcode 1</label>
            <InputText
              ref="barcode_1"
              v-model="form.barcode_1"
              :class="{
                'p-invalid': v$.form.barcode_1.$invalid && submitted,
              }"
              class="w-full"
              maxlength="13"
              input-class="w-full"
            />
            <small
              v-if="
                (v$.form.barcode_1.$invalid && submitted) ||
                v$.form.barcode_1.$pending.$response
              "
              class="p-error"
              >{{ v$.form.barcode_1.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-5">
            <label>Barcode 2</label>
            <InputText
              ref="barcode_2"
              v-model="form.barcode_2"
              class="w-full"
              maxlength="13"
              input-class="w-full"
            />
          </div>
          <div class="field col-12 md:col-5">
            <label>Brand</label>
            <InputText
              v-model="form.brand"
              ref="brand"
              class="w-full"
              input-class="w-full"
              maxlength="15"
            />
          </div>
          <div class="field col-12 md:col-5">
            <label>Deskripsi</label>
            <InputText
              v-model="form.desc"
              class="w-full"
              maxlength="35"
              input-class="w-full"
            />
          </div>

          <div class="field col-12 md:col-5">
            <label>Supplier</label>
            <Dropdown
              v-model="form.supp_id"
              :filter="true"
              :options="listSupplier"
              class="w-full"
              input-class="w-full"
              :class="{ 'p-invalid': v$.form.supp_id.$invalid && submitted }"
              optionValue="id"
              optionLabel="name"
              placeholder="Pilih Supplier"
            />
            <small
              v-if="
                (v$.form.supp_id.$invalid && submitted) ||
                v$.form.supp_id.$pending.$response
              "
              class="p-error"
              >{{ v$.form.supp_id.required.$message }}</small
            >
          </div>

          <div class="field col-12 md:col-5">
            <label>Kategori</label>
            <Dropdown
              v-model="form.ctg_id"
              :filter="true"
              :options="listKategori"
              class="w-full"
              input-class="w-full"
              :class="{ 'p-invalid': v$.form.ctg_id.$invalid && submitted }"
              optionValue="id"
              optionLabel="name"
              placeholder="Pilih Kategori"
              @change="onChangeCategory"
            />
            <small
              v-if="
                (v$.form.ctg_id.$invalid && submitted) ||
                v$.form.ctg_id.$pending.$response
              "
              class="p-error"
              >{{ v$.form.ctg_id.required.$message }}</small
            >
          </div>
        </div>
        <div class="border-bottom-1 border-400 mb-3" />
        <div class="formgrid grid">
          <div class="field col-12 md:col-3">
            <label>Satuan Besar</label>
            <Dropdown
              v-model="form.unit_b"
              :filter="true"
              :options="listSatuan"
              class="w-full"
              input-class="w-full"
              optionValue="sat"
              optionLabel="sat"
              placeholder="Satuan"
              :class="{ 'p-invalid': v$.form.unit_b.$invalid && submitted }"
              :filter-fields="['sat', 'name', 'qty']"
              @change="inputSatuan"
            >
              <template #option="slotProps">
                <div>
                  <table class="" style="width: 100%">
                    <tr>
                      <th>{{ slotProps.option.sat }}</th>
                      <th class="text-right">QTY</th>
                    </tr>
                    <tr>
                      <td>{{ slotProps.option.name }}</td>
                      <td class="text-right">{{ slotProps.option.qty }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </Dropdown>
            <small
              v-if="
                (v$.form.unit_b.$invalid && submitted) ||
                v$.form.unit_b.$pending.$response
              "
              class="p-error"
              >{{ v$.form.unit_b.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <label>Isi</label>
            <InputNumber
              v-model="form.ubox"
              class="w-full"
              :min="1"
              locale="id-ID"
              input-class="w-full"
              :class="{ 'p-invalid': v$.form.ubox.$invalid && submitted }"
              :allow-empty="false"
              @input="form.ubox = $event.value"
            />
            <small
              v-if="
                (v$.form.ubox.$invalid && submitted) ||
                v$.form.ubox.$pending.$response
              "
              class="p-error"
              >{{ v$.form.ubox.minValue.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <label>Satuan Kecil</label>
            <Dropdown
              v-model="form.unit_s"
              :filter="true"
              :options="listKecil"
              class="w-full"
              input-class="w-full"
              optionValue="sat"
              optionLabel="sat"
              placeholder="Satuan"
              :class="{ 'p-invalid': v$.form.unit_s.$invalid && submitted }"
              :filter-fields="['sat', 'name', 'qty']"
              @change="changeSatuan"
            >
              <template #option="slotProps">
                <div>
                  <table class="" style="width: 100%">
                    <tr>
                      <th>{{ slotProps.option.sat }}</th>
                      <th class="text-right">QTY</th>
                    </tr>
                    <tr>
                      <td>{{ slotProps.option.name }}</td>
                      <td class="text-right">{{ slotProps.option.qty }}</td>
                    </tr>
                  </table>
                </div>
              </template>
            </Dropdown>
            <small
              v-if="
                (v$.form.unit_s.$invalid && submitted) ||
                v$.form.unit_s.$pending.$response
              "
              class="p-error"
              >{{ v$.form.unit_s.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <label>Min Order</label>
            <InputNumber
              v-model="form.min_order"
              class="w-full"
              :min="1"
              locale="id-ID"
              input-class="w-full"
              :class="{ 'p-invalid': v$.form.min_order.$invalid && submitted }"
              :allow-empty="false"
              @input="form.min_order = $event.value"
            />
            <small
              v-if="
                (v$.form.min_order.$invalid && submitted) ||
                v$.form.min_order.$pending.$response
              "
              class="p-error"
              >{{ v$.form.min_order.minValue.$message }}</small
            >
          </div>

          <div class="field col-12 md:col-6">
            <label>Stock Min</label>
            <InputNumber
              v-model="form.umin"
              :min="1"
              locale="id-ID"
              :min-fraction-digits="0"
              :max-fraction-digits="2"
              class="w-full"
              input-class="w-full"
              :allow-empty="false"
              :class="{ 'p-invalid': v$.form.umin.$invalid && submitted }"
            />
            <small
              v-if="
                (v$.form.umin.$invalid && submitted) ||
                v$.form.umin.$pending.$response
              "
              class="p-error"
              >{{ v$.form.umin.minValue.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-6">
            <label>Stock Max</label>
            <InputNumber
              v-model="form.umax"
              class="w-full"
              input-class="w-full"
              :min="0"
              locale="id-ID"
              :min-fraction-digits="0"
              :max-fraction-digits="2"
              :allow-empty="false"
            />
          </div>

          <div class="field col-6 md:col-6">
            <label>Cost</label>
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon"> Rp </span>
              <InputNumber
                v-model="form.cost"
                class="w-full"
                input-class="w-full"
                :min="0"
                locale="id-ID"
                :min-fraction-digits="0"
                :max-fraction-digits="2"
                :allow-empty="false"
                @input="form.cost = $event.value"
              />
            </div>
          </div>
          <!-- <div class="field col-6 md:col-6">
            <label>Potongan/Disc</label>
            <div class="p-inputgroup flex-1">
              <span v-if="form.disc > 100" class="p-inputgroup-addon">
                Rp
              </span>
              <InputNumber
                v-model.number="form.disc"
                locale="id-ID"
                :min="0"
                :minFractionDigits="0"
                :maxFractionDigits="2"
                class="w-full"
                input-class="w-full"
                @input="inputDisc($event)"
              />
              <span v-if="form.disc <= 100" class="p-inputgroup-addon">
                %
              </span>
            </div>
          </div> -->
          <div class="field col-12 md:col-6">
            <label>STD MARGIN</label>
            <div class="p-inputgroup flex-1">
              <InputNumber
                v-model="form.std_mrg"
                :min="0"
                locale="id-ID"
                :min-fraction-digits="0"
                :max-fraction-digits="2"
                class="w-full"
                input-class="w-full"
                @input="form.std_mrg = $event.value"
                :allow-empty="false"
              />
              <span class="p-inputgroup-addon"> % </span>
            </div>
            <small
              v-if="
                (v$.form.std_mrg.$invalid && submitted) ||
                v$.form.std_mrg.$pending.$response
              "
              class="p-error"
              >{{ v$.form.std_mrg.maxValue.$message }}</small
            >
          </div>

          <!-- <div class="field col-12 md:col-6">
            <label>PPN</label>
            <div class="p-inputgroup flex-1">
              <InputNumber
                v-model="form.tax"
                class="w-full"
                locale="id-ID"
                :min="0"
                :minFractionDigits="0"
                :max-fraction-digits="2"
                input-class="w-full"
                :allow-empty="false"
                @input="form.tax = $event.value"
              />
              <span class="p-inputgroup-addon"> % </span>
            </div>
          </div> -->
          <div class="field col-12 md:col-6">
            <label>Price</label>
            <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon"> Rp </span>
              <InputNumber
                v-model="form.price"
                class="w-full"
                input-class="w-full"
                :min="0"
                locale="id-ID"
                :min-fraction-digits="0"
                :allow-empty="false"
              />
            </div>
          </div>

          <div class="field col-12 md:col-6">
            <label>tag</label>
            <div>
              <AutoComplete
                id="autoComplete"
                class="w-full"
                input-class="w-full"
                v-model="form.tag"
                multiple
                :suggestions="listTags"
                dropdown
                autoHighlight
                @keydown.enter="customInput"
                @complete="searchListTags"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        @click="$emit('close')"
        class="p-button-text mr-2"
      />
      <Button
        :loading="loading"
        label="Simpan"
        icon="pi pi-check"
        @click="handleSubmit(!v$.$invalid)"
      />
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, minValue, required, maxValue } from '@vuelidate/validators'
import { ceiling, round } from '@/helpers/index'
export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listDivisi: {
      type: Array,
      default() {
        return []
      },
    },
    listDepartemen: {
      type: Array,
      default() {
        return []
      },
    },
    listSubDepartemen: {
      type: Array,
      default() {
        return []
      },
    },
    listKategori: {
      type: Array,
      default() {
        return []
      },
    },
    listSatuan: {
      type: Array,
      default() {
        return []
      },
    },
    listKecil: {
      type: Array,
      default() {
        return []
      },
    },
    listSupplier: {
      type: Array,
      default() {
        return []
      },
    },
    listGlobalSetting: {
      type: Array,
      default() {
        return []
      },
    },
    listGlobalTags: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      listTags: [],
      listSatuanItem: [],
      kategori: [],
      listDepartemenFiltered: [],
      listSubDepartemenFiltered: [],
      listKategoriFiltered: [],
    }
  },
  computed: {
    form() {
      return this.item
    },
    newPrice() {
      const std_mrg = this.form.std_mrg
      // const tax = this.form.tax
      let cost = this.form.cost
      let price = round(cost / (1 - std_mrg / 100.0))
      //let price =  round(((100 + std_mrg)/100) * cost, 2)
      // let taxv = floor(price * (tax / 100.0))
      price = ceiling(price)

      // const idx = this.listGlobalSetting.findIndex((item) => item.key === 'ppn')
      // const ppn = idx != -1 ? this.listGlobalSetting[idx].value : 10

      return price
    },
  },
  watch: {
    newPrice(value) {
      if (value) {
        this.form.price = ceiling(value)
      }
    },
  },
  async mounted() {
    this.listSatuanItem = []
    let unit_l = this.listSatuan.find((item) => item.sat === this.form.unit_b)
    this.listSatuanItem.push(unit_l)
    let unit_s = this.listSatuan.find((item) => item.sat === this.form.unit_s)
    this.listSatuanItem.push(unit_s)
  },
  methods: {
    enterName() {
      // this.$refs.sku.$el.querySelector('input').focus()
    },
    customInput() {
      const autoComplete = document.getElementById('autoComplete')
      if (autoComplete.value) {
        this.form.tag.push(autoComplete.value)
        autoComplete.value = ''
        this.listTags = []
      }
    },
    inputDisc(event) {
      this.form.disc = event.value
    },
    inputSatuan(event) {
      const idx = this.listSatuan.findIndex((item) => item.sat === event.value)
      if (idx !== -1)
        this.form.ubox = this.listSatuan[idx].qty
        this.form.unit_s = event.value
      this.changeSatuan()
    },
    changeSatuan() {
      this.listSatuanItem = []
      let unit_l = this.listSatuan.find((item) => item.sat === this.form.unit_b)
      this.listSatuanItem.push(unit_l)
      let unit_s = this.listSatuan.find((item) => item.sat === this.form.unit_s)
      this.listSatuanItem.push(unit_s)
    },
    onChangeCategory(event) {
      const idx = this.listKategori.findIndex((item) => item.id === event.value)
      if (idx !== -1) {
        this.kategori = this.listKategori[idx]
        this.form.std_mrg = this.listKategori[idx].std_mrg
        // if (this.listKategori[idx].std_mrg === 0) {
        //   this.form.std_mrg = this.listKategori[idx].std_mrg_dept
        // }
      }
    },
    searchListTags(event) {
      this.listTags = event.query
        ? [...Array(12).keys()].map((item) =>
            item === 0 ? event.query : event.query + '-' + (item - 1)
          )
        : [...this.listGlobalTags]
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', { ...this.form })
    },
    OnCheckClick() {
      // for casting active checkbox checked value to int
      this.form.active = this.form.active == 0 ? 1 : 0
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Nama Item harus diisi.', required),
        },
        sku: {
          required: helpers.withMessage('Sku Item harus diisi.', required),
        },
        barcode_1: {
          required: helpers.withMessage('Barcode 1 harus diisi.', required),
        },
        supp_id: {
          required: helpers.withMessage('Supplier harus dipilih.', required),
        },
        ctg_id: {
          required: helpers.withMessage('Kategori harus diisi.', required),
        },
        unit_s: {
          required: helpers.withMessage('Satuan Kecil harus diisi.', required),
        },
        unit_b: {
          required: helpers.withMessage('Satuan Besar harus diisi.', required),
        },
        ubox: {
          minValue: helpers.withMessage(
            'Isi satuan minimal 1 atau lebih',
            minValue(1)
          ),
        },
        umin: {
          minValue: helpers.withMessage('stock min 1 atau lebih', minValue(1)),
        },
        min_order: {
          minValue: helpers.withMessage(
            'Isi minimal order 1 atau lebih',
            minValue(1)
          ),
        },
        std_mrg: {
          maxValue: helpers.withMessage(
            'Margin harus di bawah 100%',
            maxValue(99.99)
          ),
        },
      },
    }
  },
}
</script>
