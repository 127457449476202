<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Item</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Item</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Item"
          class="mr-2"
          @click="onAddData"
        />
        <Button
          :loading="isLoadingExport"
          label="Export"
          class="p-button-outlined"
          icon="pi pi-file"
          @click="onExport()"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="category"
            header="Kategori"
            filterField="ctg_id"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_kategori"
                :filter="true"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Kategori"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            field="supplier"
            header="nama supplier"
            style="min-width: 15rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>

          <Column
            field="name"
            header="Nama"
            style="min-width: 15rem"
            sortable
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="sku"
            header="Sku"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="barcode_1"
            header="barcode"
            filterField="barcode"
            sort-field="barcode_1"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="price" header="PRICE" style="min-width: 8rem">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.price) }}
            </template>
          </Column>
          <Column field="disc" header="DISC" sortable style="min-width: 8rem">
            <template #body="slotProps">
              {{
                slotProps.data.disc > 100
                  ? formatCurrency(slotProps.data.disc)
                  : slotProps.data.disc == null
                  ? `0%`
                  : `${slotProps.data.disc}%`
              }}
            </template>
          </Column>
          <Column field="stock" header="STOCK" style="min-width: 8rem">
            <template #body="slotProps">
              {{ slotProps.data.stock }}
            </template>
          </Column>

          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit item' : 'Tambah item'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-item
        :item="form"
        :loading="isLoadingSave"
        :list-kategori="list_kategori"
        :list-satuan="list_satuan"
        :list-kecil="list_satuan_kecil"
        :list-tags="listTags"
        :list-supplier="list_supplier"
        :list-global-setting="list_global_setting"
        :list-global-tags="list_global_tags"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Item"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import RefService from '@/services/RefService'
import SettingService from '@/services/SettingService'
import SupplierService from '@/services/SupplierService'
import KategoriService from '@/services/KategoriService'
import SatuanService from '@/services/SatuanService'
import ItemService from '@/services/ItemService'
import FormItem from '@/components/master/FormItem'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import { formatCurrency } from '@/helpers/index'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Item',
  components: {
    FormItem,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      isLoadingExport: false,
      tax: 0,
      refService: null,
      settingService: null,
      supplierService: null,
      kategoriService: null,
      satuanService: null,
      itemService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      list_kategori: [],
      list_satuan: [],
      list_satuan_kecil: [],
      list_supplier: [],
      list_global_setting: [],
      list_global_tags: [],
      items: [],
      listTags: [
        { id: 'fashion', name: 'fashion' },
        { id: 'best-seller', name: 'best-seller' },
        { id: 'disc-10', name: 'disc-10' },
        { id: 'lainnya', name: 'lainnya' },
      ],
      form: {
        id: 0,
        ctg_id: 0,
        barcode_1: '',
        barcode_2: '',
        unit_s: '',
        unit_b: '',
        name: '',
        umin: 1,
        umax: 0,
        active: 1,
        brand: '',
        ubox: 0,
        min_order: 1,
        cost: 0,
        disc: 0,
        price: 0,
        tax: 0,
        std_mrg: 0,
        sku: '',
        tag: [],
        desc: '',
        supp_id: 0,
      },
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'name',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        item: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ctg_id: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        sku: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supp_kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        barcode_1: { value: null, matchMode: FilterMatchMode.CONTAINS },
        barcode_2: { value: null, matchMode: FilterMatchMode.CONTAINS },
        barcode: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.refService = new RefService()
    this.settingService = new SettingService()
    this.kategoriService = new KategoriService()
    this.satuanService = new SatuanService()
    this.supplierService = new SupplierService()
    this.itemService = new ItemService()
  },
  async mounted() {
    this.loadData()
    // kategori

    this.loadRefTags()

    this.settingService
      .get()
      .then((res) => {
        this.list_global_setting = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.kategoriService
      .get()
      .then((res) => {
        this.list_kategori = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Kategori', this)
      })

    this.supplierService
      .get()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.satuanService
      .get()
      .then((res) => {
        this.list_satuan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Satuan', this)
      })
    this.satuanService
      .getSmallest()
      .then((res) => {
        this.list_satuan_kecil = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Satuan Kecil', this)
      })
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadRefTags() {
      this.refService
        .getTags()
        .then((res) => {
          this.list_global_tags = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Tags', this)
        })
        .finally(() => (this.isLoading = false))
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.itemService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      data.disc = data.disc > 0 ? data.disc : 0
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onAddData() {
      const idx = this.list_global_setting.findIndex(
        (item) => item.key === 'tax'
      )
      this.tax = idx != -1 ? this.list_global_setting[idx].value : 0

      this.form = {}
      this.form.id = 0
      this.form.name = ''
      this.form.barcode_1 = ''
      this.form.barcode_2 = ''
      this.form.unit_s = ''
      this.form.unit_b = ''
      this.form.umin = 1
      this.form.umax = 0
      this.form.active = 1
      this.form.brand = ''
      this.form.ubox = 1
      this.form.min_order = 1
      this.form.ctg_id = ''
      this.form.tax = this.tax
      this.form.cost = 0
      this.form.disc = 0
      this.form.price = 0
      this.form.std_mrg = 0
      this.form.supp_id = null
      this.form.sku = ''
      this.form.tag = []
      this.form.desc = ''
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      const payload = {
        supp_id: data.supp_id || null,
        ctg_id: data.ctg_id,
        barcode_1: data.barcode_1,
        barcode_2: data.barcode_2,
        unit_s: data.unit_s,
        unit_b: data.unit_b,
        name: data.name,
        umin: data.umin,
        umax: data.umax,
        active: data.active,
        brand: data.brand,
        ubox: data.ubox,
        min_order: data.min_order,
        cost: data.cost,
        disc: data.disc,
        price: data.price,
        tax: data.tax,
        std_mrg: data.std_mrg,
        sku: data.sku,
        tag: data.tag,
        desc: data.desc,
      }
      if (data.id === 0) {
        this.itemService
          .add(payload)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.loadRefTags()
              this.$toast.add({
                severity: 'success',
                summary: 'Data item',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data item', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id > 0) {
        this.itemService
          .update(data.id, payload)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.loadRefTags()
              this.$toast.add({
                severity: 'success',
                summary: 'Data item',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data item', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      this.itemService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Item',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onExport() {
      this.isLoadingExport = true
      const dataService = new ItemService()
      dataService
        .export()
        .then((response) => {
          const date = new Date()
          const year = date.getFullYear()
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const day = String(date.getDate()).padStart(2, '0')
          const formattedDate = `${year}${month}${day}`

          const filename = `gnitem-${formattedDate}.db3`

          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => {
          this.isLoadingExport = false
        })
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
