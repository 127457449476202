import http from '../clients/Axios'

export default class SatuanService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'sat' || el === 'name') {
            filters_param = filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/satuan/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  // async paginate(param) {
  //   param = param ? param : ''
  //   const filter = {}
  //   filter.page = param.page
  //   filter.rows = param.rows
  //   filter.sortField = param.sortField
  //   filter.sortOrder = param.sortOrder
  //   filter.div = param.div
  //   filter.name = param.name
  //   const res = await http.post('/satuan/paginate', JSON.stringify(filter))
  //   return res
  // }

  async get() {
    const res = await http.get('/satuan')
    return res
  }

  async getSmallest() {
    const res = await http.get('/satuan?qty=1')
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/satuan${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/satuan', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/satuan/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/satuan/${id}`)
    return res
  }
}
